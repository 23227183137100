import { Skeleton } from '@chakra-ui/react';
import React from 'react';

export const ListLoader = () => {
  return (
    <>
      <Skeleton
        height="60px"
        width="100%"
        startColor="neutral.700"
        endColor="neutral.800"
        mb="8px"
      />
      <Skeleton
        height="60px"
        width="100%"
        startColor="neutral.700"
        endColor="neutral.800"
        mb="8px"
      />
      <Skeleton
        height="60px"
        width="100%"
        startColor="neutral.700"
        endColor="neutral.800"
        mb="8px"
      />
      <Skeleton
        height="60px"
        width="100%"
        startColor="neutral.700"
        endColor="neutral.800"
        mb="8px"
      />
      <Skeleton
        height="60px"
        width="100%"
        startColor="neutral.700"
        endColor="neutral.800"
        mb="8px"
      />
    </>
  );
};
