import React, { FC } from 'react';

import { AddLiquidityTransaction } from '@app/components/TransactionsHistory/components/AddLiquidityTransaction';
import { RemoveLiquidityTransaction } from '@app/components/TransactionsHistory/components/RemoveLiquidityTransaction';
import { SwapTransaction } from '@app/components/TransactionsHistory/components/SwapTransaction';
import { Transaction } from '@app/types/transaction';

interface Props {
  data: Transaction;
}

export const TransactionItem: FC<Props> = ({ data }) => {
  if (data.swaps?.length) {
    return (
      <SwapTransaction
        txId={data.id}
        swaps={data.swaps}
        timestamp={data.timestamp}
      />
    );
  }

  if (data.mints?.length) {
    return (
      <AddLiquidityTransaction
        txId={data.id}
        mints={data.mints}
        timestamp={data.timestamp}
      />
    );
  }

  if (data.burns?.length) {
    return (
      <RemoveLiquidityTransaction
        txId={data.id}
        burns={data.burns}
        timestamp={data.timestamp}
      />
    );
  }

  return null;
};
