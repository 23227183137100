import cn from 'clsx';
import React from 'react';

import icons from 'public/icons';

import s from './Icon.module.scss';

export type IconName = keyof typeof icons;

interface IconProps extends React.HTMLAttributes<SVGElement> {
  className?: string;
  name: IconName;
}

const Icon = ({ className, name, ...rest }: IconProps) => {
  const SVG: React.ComponentClass<any> = icons[name];

  return <SVG className={cn(s.root, className)} {...rest} />;
};

export default Icon;
