import { Text } from '@chakra-ui/react';
import Link from 'next/link';
import React, { FC } from 'react';
import { useChainExplorer } from '@app/hooks/other/useExplorerAddress';

interface Props {
  txHash: string | undefined;
}

export const ExternalLink: FC<Props> = ({ txHash }) => {
  const { explorer } = useChainExplorer();

  if (!txHash) {
    return null;
  }

  return (
    <Link
      onClick={e => e.stopPropagation()}
      href={`${explorer?.url}/tx/${txHash}`}
      target="_blank"
    >
      <Text
        fontSize="14px"
        color="neutral.400"
        textTransform="capitalize"
        textAlign="left"
        _hover={{ color: 'neutral.100' }}
      >
        View on explorer
      </Text>
    </Link>
  );
};
